import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import StyledLink from 'components/common/utils/StyledLink';
import Image from 'components/common/utils/Image';
import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import Section from 'components/common/Section';
import TestimoniesSection from 'components/common/TestimoniesSection';
import TextSectionList from 'components/common/TextSectionList';

import { testimonies, sections } from './data';
import './styles.sass';

import Hush from 'images/icons/hush.svg';
import Medal from 'images/icons/medal.svg';
import PharmaCross from 'images/icons/pharma-cross-with-heart.svg';
import HomeBulletCategorie from '../common/HomeBulletCategorie';
import TherapistCarousel from '../common/TherapistsCarousel';
import HowItWorksSection from '../HowItWorksSection';
import BestQualityImage from '../common/utils/BestQualityImage';
import PressBanner from '../Press/PressBanner';

const IndexPage = () => (
  <Layout>
    <CharlesMeta
      data={{
        title: 'Réservez une consultation avec une sexologue en ligne sur Mia.co',
        description:
          "Nos sexologues diplômées répondent à vos questions, 7 jours sur 7 par messagerie,  visioconférence ou appel téléphonique : écoute, confiance et discrétion !",
      }}
    />
    <div className="text-center text-lg-left landing-page">
      <div className="five-ladies-div d-lg-none">
        <BestQualityImage filename="five-ladies.jpg" className="five-ladies-img" alt="cinq femmes et un cannapé" />
      </div>
      <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section">
        <div className="h1 text-center main-title-landing mia-home-title align-self-center d-lg-none">
          Téléconsultez une sexologue et rejoignez la communauté&nbsp;!
        </div>
        <div className="desktop-home-top-div d-none d-lg-flex">
          <div className="home-top-left">
            <div>
              <h1>
                Téléconsultez
                <br />
                une sexologue
                <br />
                et rejoignez la communauté&nbsp;!
              </h1>
              <div className="d-flex cta-div">
                <StyledLink
                  variant="orange"
                  path="/consulter/"
                  className=""
                  margin
                >
                  CONSULTER
                </StyledLink>
              </div>
            </div>
          </div>
          <div className="home-top-right">
            <BestQualityImage
              filename="five-ladies.jpg"
              className="img-top-right"
              imgStyle={{
                objectPosition: 'center top',
              }}
            />
          </div>
        </div>
        <div className="background-color-pale-yellow info-div">
          <div className="info-list">
            <div className="text-left">
              <div className="img-wrapper"><Hush /></div>
              <span className="hush-span">CONFIDENTIEL & SÉCURISÉ</span>
            </div>
            <div>
              <div className="img-wrapper"><Medal /></div>
              <span className="medal-span">SEXOLOGUES DIPLÔMÉES</span>
            </div>
            <div>
              <div className="img-wrapper"><PharmaCross /></div>
              <span className="pharmacross-span">SUIVI & ASSISTANCE ILLIMITÉS</span>
            </div>
          </div>
          <div className="d-flex cta-div justify-content-center d-lg-none">
            <StyledLink
              variant="orange"
              path="/consulter/"
              className=""
              margin
            >
              CONSULTER
            </StyledLink>
          </div>
        </div>
      </Section>
      <Section className="bg-white pr-1 pl-1 pr-lg-5 pl-lg-5 pt-sm-2 pt-lg-0 categories-section">
        <Container className="d-flex flex-column align-items-center">
          <h2 className="h2-title">MIA VOUS ACCOMPAGNE</h2>
          <div className="d-flex">
            <HomeBulletCategorie />
          </div>
        </Container>
      </Section>
      <Section className="landing-catch-phrase-section">
        <h2 className="landing-catch-phrase">
          Mia vous permet de téléconsulter{' '}
          <br className="d-none d-lg-inline" />
          depuis chez vous des sexologues diplômées, spécialistes des questions de santé sexuelle
          féminine
        </h2>
      </Section>
      <Section className="press-banner-section">
        <div className="press-div-wrapper">
          <h2 className="h2-title text-center">Ils parlent de nous</h2>
          <PressBanner />
        </div>
      </Section>
      <HowItWorksSection />
      <Section className="two-womens-section">
        <StyledLink
          variant="orange"
          path="/consulter/"
          className=""
          margin
        >
          CONSULTER
        </StyledLink>
      </Section>
      <TestimoniesSection testimonies={testimonies} path="/consulter/" />
      <Section className="d-flex flex-column therapist-carousel-section">
        <div className="d-flex flex-column therapist-div-wrapper">
          <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
          <TherapistCarousel />
        </div>
      </Section>
      <Section className="d-flex flex-column align-items-center faq-section">
        <Container className="d-flex flex-column align-items-center">
          <h3 className="h2-title text-center">
            FAQ
          </h3>
          <TextSectionList sections={sections} />
          <div className="d-flex cta-div">
            <StyledLink
              variant="orange"
              path="/consulter/"
              margin
            >
              CONSULTER
            </StyledLink>
          </div>
        </Container>
      </Section>
    </div>
  </Layout>
);

export default IndexPage;
