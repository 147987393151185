import React from 'react';

export default [
  {
    id: 1,
    categoryTitle: 'BAISSE DE LIBIDO',
    img: 'home/baisse-de-libido-off.svg',
    imgHover: 'home/baisse-de-libido-on.svg',
    link: '/baisse-de-libido/',
  },
  {
    id: 2,
    categoryTitle: 'DOULEURS',
    img: 'home/douleur-penetration-off.svg',
    imgHover: 'home/douleur-penetration-on.svg',
    link: '/douleur/',
  },
  {
    id: 3,
    categoryTitle: "ABSENCE D'ORGASME",
    img: 'home/absence-orgasme-off.svg',
    imgHover: 'home/absence-orgasme-on.svg',
    link: '/plaisir/',
  },
  {
    id: 4,
    categoryTitle: 'DIFFICULTÉS DE COUPLE',
    img: 'home/difficulte-de-couple-off.svg',
    imgHover: 'home/difficulte-de-couple-on.svg',
    link: '/couple/',
  },
  {
    id: 5,
    categoryTitle: 'ÉPANOUISSEMENT SEXUEL',
    img: 'home/epanouissement-sexuel-off.svg',
    imgHover: 'home/epanouissement-sexuel-on.svg',
    link: '/epanouissement/',
  },
  {
    id: 6,
    categoryTitle: 'AUTRES',
    img: 'home/autres-off.svg',
    imgHover: 'home/autres-on.svg',
    link: '/problemes-sexuels/',
  },
];
