const EMBARGO_DATE = 1558497600000; // Date.parse("2019-05-22T06:00:00+02:00")

export const articles = [
  {
    media: 'Elle',
    published: true,
    featured: true,
    title: 'Une plateforme dédiée aux femmes pour consulter des sexologues en ligne',
    description:
      'Mia se veut […] faciliter l’accès à la thérapie sexologique. Une plateforme gérées par des femmes sexologues pour les femmes. #sororité',
    location:
      'https://www.elle.fr/Love-Sexe/Sexualite/Une-plateforme-dediee-aux-femmes-pour-consulter-des-sexologues-en-ligne-3915864',
    brandImage: 'press/ELLE-Magazine-Logo.svg',
    grayImage: 'press/gray/ELLE-Magazine-Logo_gray.svg',
    alt: 'Mia sur Elle',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'RTL',
    published: true,
    featured: true,
    title:
      'A changer par un titre',
    description:
      'Mia.co est la première plateforme de téléconsultation en sexologie, le besoin était là et ça va faire un bien fou',
    location:
      'https://www.rtl.fr/actu/debats-societe/consommons-nous-tous-du-porno-7900001174',
    brandImage: 'press/RTL_logo.svg',
    grayImage: 'press/gray/RTL_logo_gray.svg',
    alt: 'Mia sur RTL',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Les Echos',
    published: true,
    featured: true,
    title: 'Sextech : Mia, un « Doctolib » à l\'écoute de l\'intimité des femmes',
    description:
      'Entre SexTech et HealthTech, la toute nouvelle plateforme Mia permet aux femmes de téléconsulter une sexologue diplômée et de trouver un espace de discussion « bienveillant et sécurisant ».',
    location:
      'https://start.lesechos.fr/innovations-startups/tech-futur/sextech-mia-un-doctolib-a-lecoute-de-lintimite-des-femmes-1299062',
    brandImage: 'press/Les_echos.svg',
    grayImage: 'press/gray/Les_echos_gray.png',
    alt: 'Mia sur les Echos',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Flair',
    published: true,
    featured: true,
    title: 'MIA, la nouvelle plateforme de santé sexuelle pour consulter une sexologue',
    description:
      'Place à la consultation avec une sexologue, qui peut disposer d’une autre casquette selon la problématique soulevée : psy, sage-femme ou encore thérapeute de couple.',
    location:
      'https://www.flair.be/fr/lifestyle/mia-plateforme-sante-sexuelle-femme/',
    brandImage: 'press/Flair_logo.svg',
    grayImage: 'press/Flair_logo.svg',
    alt: 'Mia sur Flair',
    publicationDate: EMBARGO_DATE,
  },
];

export const publishedArticles = articles.filter((article) => article.published);
