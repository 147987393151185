import React from 'react';
import categories from './data';
import Image from '../utils/Image';
import { Link } from 'gatsby';

function endTouchableHover(event) {
  const touchImage = event.target;
  const touchLink = touchImage.parentElement;
  const touchSecondImage = touchLink.querySelector('.bl-img-hover');
  const touchSpan = touchLink.querySelector('.bl-span');
  touchLink.classList.remove('touch-bl-categories');
  touchImage.classList.remove('touch-bl-img');
  touchSecondImage.classList.remove('touch-categories-img-hover');
  touchSpan.classList.remove('touch-bl-span');
}

function touchableHover(event) {
  const tempTarget = event;
  event.persist();
  const touchImage = tempTarget.target;
  const touchLink = touchImage.parentElement;
  const touchSecondImage = touchLink.querySelector('.bl-img-hover');
  const touchSpan = touchLink.querySelector('.bl-span');
  touchLink.classList.add('touch-bl-categories');
  touchImage.classList.add('touch-bl-img');
  touchSecondImage.classList.add('touch-categories-img-hover');
  touchSpan.classList.add('touch-bl-span');
  setTimeout(() => {
    endTouchableHover(tempTarget);
  }, 400);
}

function HomeBulletCategorie() {
  return (
    <div className="d-flex flex-wrap justify-content-sm-between categories-div-wrapper">
      {categories.map((categorie) => (
        <Link className="d-flex flex-column categories-div bl-categories no-underline-hover" key={categorie.id} to={categorie.link}>
          <Image filename={categorie.img} className="categories-img bl-img" width={98} height={98} alt="test" onTouchStart={touchableHover} />
          <Image filename={categorie.imgHover} className="categories-img-hover bl-img-hover" />
          <span className="bl-span">{categorie.categoryTitle}</span>
        </Link>
      ))}
    </div>
  );
}

export default HomeBulletCategorie;
