import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

const BestQualityImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "five-ladies.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: TRACED_SVG)
          }
        }
      }
    `}
    render={(data) => {
      const image = data.file;
      if (!image) {
        return null;
      }
      return (
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          className="img-fluid"
          {...props}
        />
      );
    }}
  />
);

BestQualityImage.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default BestQualityImage;
