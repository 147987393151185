import React from 'react';

import { chunk } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'gatsby';
import Image from 'components/common/utils/Image';

import ChevronLeft from 'images/press/press-chevron-left.svg';
import ChevronRight from 'images/press/press-chevron-right.svg';
import Quote from 'images/press/left-quotes-sign.svg';

import { publishedArticles } from '../articles';
import './styles.sass';

const ArticleSmallCard = ({
  brandImage,
  media,
  location,
  description,

}) => (
  <div className="article-small-card">
    <div className="quote-wrapper">
      <Quote className="article-small-card-quote" />
    </div>
    <div className="article-small-card-content">
      <span>{description}</span>
      <Image
        filename={brandImage}
        alt={media}
        className="article-small-card-img"
      />
    </div>
  </div>
);
class PressBanner extends React.Component {
  render() {
    if (publishedArticles.length === 0) {
      return null;
    }
    const featuredArticles = publishedArticles.filter((article) => article.featured);
    const articles = featuredArticles.concat(featuredArticles).concat(featuredArticles);
    const slicesBy6 = chunk(articles, 6);
    const slicesBy3 = chunk(articles, 3);
    const previousNode = <ChevronLeft width={32} height={32} />;
    const nextNode = <ChevronRight width={32} height={32} />;
    const { linkToPressPage } = this.props;
    return (
      <div className="mb-4 mb-lg-5 videowall-scroll-div-wrapper">
        <div className="x-scroll-div d-flex d-lg-none">
          {featuredArticles.map((article) => (
            <div
              key={article.id}
              className="d-inline-block px-3"
            >
              <ArticleSmallCard
                linkToPress={linkToPressPage}
                {...article}
              />
            </div>
          ))}
        </div>
        <Carousel
          indicators={false}
          className="d-none d-lg-flex"
          prevIcon={previousNode}
          nextIcon={nextNode}
          interval={null}
          controls
        >
          {slicesBy3.map((slice) => (
            <Carousel.Item key={slice.id} className="">
              <Row className="mx-0">
                {slice.map((article) => (
                  <Col
                    xs={4}
                    key={article.id}
                    className="d-flex flex-column align-items-center"
                  >
                    <ArticleSmallCard
                      className=""
                      linkToPress={linkToPressPage}
                      {...article}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default PressBanner;
